import React, { useState } from 'react';
//import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function SignupForm({ onLogin }) {
    const [phone, setPhone] = useState('');
    const [contact, setContact] = useState('');
    const [company, setCompany] = useState('');
    const [company_address, setCompanyAddress] = useState('');
    const [company_email, setCompanyEmail] = useState('');
    const [company_pan, setCompanyPAN] = useState('');
    const [gst, setGst] = useState('');
    const [cheque, setCheque] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showSignupForm, setShowSignupForm] = useState(false);
    const [signupEmail, setSignupEmail] = useState('');
    const [signupName, setSignupName] = useState('');
    const [signupPassword, setSignupPassword] = useState('');
    const [signupConfirmPassword, setSignupConfirmPassword] = useState('');

    //   const handleLogin = async (e) => {
    //     e.preventDefault();
    //     try {
    //       const response = await axios.post('https://app.cargoa.in/loginapi/login', {
    //         email: email,
    //         password: password,
    //       });
    //       localStorage.setItem('token', response.data.token);
    //       onLogin();
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   };

    //   const handleSignup = async (e) => {
    //     e.preventDefault();
    //     try {
    //       await axios.post('https://app.cargoa.in/loginapi/register', {
    //         email: signupEmail,
    //         password: signupPassword,
    //         confirmPassword: signupConfirmPassword,
    //       }).then((response) => {
    //         setShowSignupForm(!showSignupForm);
    //         localStorage.setItem('token', response.data.token);
    //       });

    //       onLogin();
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   };

    //   const toggleSignupForm = () => {
    //     setShowSignupForm(!showSignupForm);
    //   };

    const navigate = useNavigate();

    return (
        <div className='App' style={{ justifyContent: 'center' }} >
            <div style={{ width: '70%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ background: '#ffffff', height: '85%', width: '80%', borderRadius: '10px' }}>
                    <div className="w-75 h-100 mx-auto " style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div>
                            <h2 style={{ textAlign: 'center', fontSize: '200%', marginBottom: '0', marginTop: '3%' }}>Sign up</h2>
                            <p style={{ textAlign: 'center', color: 'grey', marginTop: '1.5%', marginBottom: '0' }}>Enter your credentials to access your account</p>
                        </div>


                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <div style={{ width: '45%' }}>
                                <h6>Phone Number</h6>
                                <input type="text" className="form-control h-75" placeholder="+234"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)} />
                            </div>
                            <div style={{ width: '45%' }}>
                                <h6>Contact mobile</h6>
                                <input type="text" className="form-control h-75" placeholder="+234"
                                    value={contact}
                                    onChange={(e) => setContact(e.target.value)} />
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <div style={{ width: '45%' }}>
                                <h6>Company</h6>
                                <input type="text" className="form-control h-75" placeholder="@ LTD company"
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)} />
                            </div>
                            <div style={{ width: '45%' }}>
                                <h6>Company Address (Optional)</h6>
                                <input type="text" className="form-control h-75" placeholder="@ 2400, Copenhagen, Denmark"
                                    value={company_address}
                                    onChange={(e) => setCompanyAddress(e.target.value)} />
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <div style={{ width: '45%' }}>
                                <h6>Company Email</h6>
                                <input type="text" className="form-control h-75" placeholder="@ ltdcompany@email.com"
                                    value={company_email}
                                    onChange={(e) => setCompanyEmail(e.target.value)} />
                            </div>
                            <div style={{ width: '45%' }}>
                                <h6>Company PAN (Optional)</h6>
                                <input type="text" className="form-control h-75" placeholder="@ Company PAN"
                                    value={company_pan}
                                    onChange={(e) => setCompanyPAN(e.target.value)} />
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <div style={{ width: '45%' }}>
                                <h6>Company GST (Optional)</h6>
                                <input type="text" className="form-control h-75" placeholder="@ Company GST"
                                    value={gst}
                                    onChange={(e) => setGst(e.target.value)} />
                            </div>
                            <div style={{ width: '45%' }}>
                                <h6>Company Crossed Cheque</h6>
                                <input type="text" className="form-control h-75" placeholder="@ Company Crossed Cheque"
                                    value={cheque}
                                    onChange={(e) => setCheque(e.target.value)} />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', height: '12%', marginBottom: '3%', justifyContent: 'space-between' }}>
                            <button type="button" style={{ height: '60%' }} className="btn btn-primary w-50 mx-auto">Create an account</button>
                            <p className="text-center" style={{ marginBottom: '0', height: '25px', marginTop: '0' }}>Already have an account? <button type="button" onClick={() => navigate('/login')} style={{ marginBottom: '5%', textDecoration: 'none', padding: '0', verticalAlign: 'baseline' }} className="btn btn-link">Sign in</button></p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
export default SignupForm;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import screen from '../screen.jpg'
import PhoneInput from 'react-phone-input-2'
import './Login.css'
import 'react-phone-input-2/lib/style.css'
import GoogleLogin from 'react-google-login';
import ResetPasswordForm from './ResetPasswordform';

function LoginForm({ onLogin,logoutMessage }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showSignupForm, setShowSignupForm] = useState(false);
  const [signupEmail, setSignupEmail] = useState('');
  const [signupName, setSignupName] = useState('');
  const [signupPassword, setSignupPassword] = useState('');
  const [signupConfirmPassword, setSignupConfirmPassword] = useState('');
  const [getDetails,setGetDetails] =  useState(false)
  const [phone, setPhone] = useState('');
  const [contact, setContact] = useState('');
  const [company, setCompany] = useState('');
  const [company_address, setCompanyAddress] = useState('');
  const [company_email, setCompanyEmail] = useState('');
  const [company_pan, setCompanyPAN] = useState('');
  const [gst, setGst] = useState('');
  const [cheque, setCheque] = useState('');
  const [res,setRes] =useState('')
  const [passwordreset,setPasswordreset] =useState(false)
  const [resetmessage,setResetmessage] =useState('')
  const [otp,setOtp] =useState('')
  const [otppage,setOtppage] =useState(false)
  const [err,setErr] = useState('')
  const [signupmessage,setSignupmessage] =useState('')
  const handleLogin = async (e) => {
    e.preventDefault();
   if(phone && password != ''){
    let mail = email.trim().toLowerCase();
    try {
      await axios.post('https://app.cargoa.in/loginapi/login', {
        email: mail,
        mobile:phone,
        password: password,
      }).then((res)=>{
        localStorage.setItem('token', res.data.token);
        onLogin();
      });
     
    } catch (error) {
      console.error(error.response.data);
      setRes(error.response.data)
      setTimeout(()=>{
        setRes('')
      },5000)
    }
  }
  };
const navigate = useNavigate()

  const handleSignup = async (e) => {
    e.preventDefault();
    let signupmail = signupEmail.trim().toLowerCase();
    if(email !='' && phone!='' && signupName!='' && signupPassword!='' && phone.length>=9){
    try {
      await axios.post('https://app.cargoa.in/loginapi/signup', {
        password: signupPassword,
        email: email,
        mobile:phone,
        name:signupName
      }).then((response) => {
      //  setShowSignupForm(!showSignupForm);
       
        setOtppage(true)
      });

      
    } catch (error) {
      setSignupmessage("Mobile/Email already exist")
      console.error(error);
    }
  }else{
    setSignupmessage("Please fill all details")
  }
  };
const onUpdateData =event=>{
  event.preventDefault();
axios.post('https://app.cargoa.in/loginapi/updateuser',{
  company:company,
  company_address:company_address,
  company_email:company_email,
  company_pan:company_pan,
  gst:gst,
  cheque:cheque,
  mobile:phone
}).then(res =>{
  setShowSignupForm(!showSignupForm);
  setGetDetails(false)
  setOtppage(false)
}).catch((err)=> console.log(err))
}
  const onVerifyOTP =() =>{
    if(otp!=''){
    axios.post('https://app.cargoa.in/loginapi/verify-otp',{mobile:phone,otp:otp}).then(res=> {
      setGetDetails(true)
    }).catch(err => {setErr("Invalid OTP")
      setTimeout(()=>{
        setErr('')
      },5000)
  })
}else{
  setErr("Enter OTP")
}
  }
  const onForgotPassword =event=>{
    event.preventDefault();
    axios.post('https://app.cargoa.in/loginapi/forgot-password',{email:email}).then(res=> setResetmessage("Reset link sent to mail")).catch((err)=>console.log(err))
  }
  const toggleSignupForm = () => {
    setShowSignupForm(!showSignupForm);
   
  };
  

  return (
    <div className='App' >
     
      {!getDetails? (
        <>
      {!passwordreset? <>
      {!showSignupForm ? (
        <>
         
          <form onSubmit={handleLogin}  className='login-form'  >
       
          <div className='login-div'>
              <div className="h-100 mx-auto login-div2 " >

                <div>
                  <h2 style={{ textAlign: 'center', fontSize: '30px', marginBottom: '0', marginTop: '5%', color: '#12104f' }}>Sign in</h2>
                  <p style={{ textAlign: 'center', color: 'grey', marginTop: '1.5%', marginBottom: '0', fontSize: '12px', color:'#b1b4c6' }}>Enter your credentials to access your account</p>
                </div>
                {logoutMessage? <div className="success-message" style={{color:"green"}}>Successfully Logged out</div>:""}
                <div style={{ height: '10%' }}>
                  <h6 style={{ color: '#12104f'}} className='h-font' >Mobile</h6>
                  <input type="number" className="form-control input-box" placeholder="9876543210"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)} required />
                </div>


                <div style={{ height: '10%' }}>
                  <h6 style={{ color: '#12104f' }} className='h-font' >Password</h6>
                  <input type="password" className="form-control input-box" placeholder="************"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)} required />
                </div>

                
                <h6 style={{ margin: '0', color: '#12104f',cursor:"pointer"}} className='h-font'  onClick={()=>setPasswordreset(true)}>Forgot Password?</h6>
                <button type="submit" className="btn btn-primary w-100 h-font"  style={{ background: '#386af5', height: '7%', borderRadius: '4px'}}>Sign in</button>
               {res? <p style={{color:"red"}}>{res}</p>:"" }



                <div className="lines" >
                  <hr></hr>
                  <span>Or, sign in with your email</span>
                  <hr></hr>
                </div>
                <button type="button" className="btn btn-outline-primary w-100 h-font" style={{height: '7%', borderRadius: '4px'}}>Sign in with Google</button>
                {/* <GoogleLogin
                   clientId=''
                    buttonText="Sign in with Google"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                  /> */}
                <p className="text-center" style={{ margin: '0', fontSize: '12px', color:'#b1b4c6' }}>Dont't have an account? <button type="button" onClick={toggleSignupForm} style={{ marginBottom: '5%', textDecoration: 'none', padding: '0', verticalAlign: 'baseline', fontSize: '12px' }} className="btn btn-link">Sign up</button> </p>

              </div>
            </div>

          </form>
         
        </>
      ) : (
        <>
         <div className='login-form' >
            <div className='login-div'>
              <div className="h-100 mx-auto login-div2 " >

                <div>
                  <h2 style={{ textAlign: 'center', fontSize: '30px', marginBottom: '0', marginTop: '5%', color: '#12104f' }}>Sign up</h2>
                  <p style={{ textAlign: 'center', color: 'grey', marginTop: '1.5%', marginBottom: '0', fontSize: '12px' }}>Enter your credentials to access your account</p>
                </div>
                {!otppage? 
                <>
                {signupmessage? <p style={{color:"red"}}>{signupmessage}</p> :""}
                <div style={{ height: '10%' }}>
                  <h6 style={{ color: '#12104f' }} className='h-font' >Name</h6>
                  <input type="text" className="form-control input-box" placeholder="@ john"
                    value={signupName}
                    onChange={(e) => setSignupName(e.target.value)} />
                </div>

                <div style={{ height: '10%' }}>
                  <h6 style={{ color: '#12104f' }} className='h-font'>Mobile</h6>
                  <input type="number" style={{textDecoration:"none"}} className="form-control input-box in-num" placeholder="9876543210" minLength='10'
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)} />
                </div>

                <div style={{ height: '10%' }}>
                  <h6 style={{ color: '#12104f' }} className='h-font'>Email</h6>
                  <input type="email" className="form-control input-box" placeholder="Enter Your Email Here"
                    value={email}
                    min='8'
                    onChange={(e) => setEmail(e.target.value)} />
                </div>

                <div style={{ height: '10%' }}>
                  <h6 style={{ color: '#12104f' }} className='h-font'> Password</h6>
                  <input type="password" className="form-control input-box" placeholder=" password"
                    value={signupPassword}
                    onChange={(e) => setSignupPassword(e.target.value)} />
                </div>
                    <p style={{ margin: 0, fontSize: '12px', color:'#b1b4c6'}}>I agree, to the 
                    <button style={{ textDecoration: 'none', padding: '0', verticalAlign: 'baseline', fontSize: '12px' }} className="btn btn-link">&nbsp;terms of service&nbsp; </button> 
                    and  
                    <button style={{ textDecoration: 'none', padding: '0', verticalAlign: 'baseline', fontSize: '12px' }} className="btn btn-link">&nbsp;privacy policy</button>
                    </p></>
                    : <>
                    {err ? <p style={{ textAlign: 'center', marginBottom: '0', color: 'red' }}>{err}</p>:""}
                    <p style={{color:"green",textAlign:"center"}}>OTP sent to your mobile number</p>
                    <div style={{ height: '10%' }}>
                  <h6 style={{ color: '#12104f' }} className='h-font'>OTP</h6>
                  <input type="text" className="form-control input-box" placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)} />
                </div>
                <button type="button" className="btn btn-primary w-100 h-font" onClick={onVerifyOTP} style={{ background: '#386af5', height: '7%', borderRadius: '4px'}}>Submit OTP</button>
                    </>}
                   
                    {!otppage?   <button type="button" className="btn btn-primary w-100 h-font" onClick={handleSignup} style={{ background: '#386af5', height: '7%', borderRadius: '4px'}}>Send OTP</button> :""}

                <div className="lines" >
                  <hr></hr>
                  <span>Or, sign in with your email</span>
                  <hr></hr>
                </div>
                <button type="button" className="btn btn-outline-primary w-100 h-font" style={{height: '7%', borderRadius: '4px'}} >Sign in with Google</button>
                <p className="text-center" style={{ margin: 0, fontSize: '12px', color:'#b1b4c6'}}>Already have an account? <button type="button" onClick={toggleSignupForm} style={{ marginBottom: '4%', textDecoration: 'none', padding: '0', verticalAlign: 'baseline', fontSize: '12px' }} className="btn btn-link">Sign in</button> </p>



              </div>
            </div>
          </div>

        </>

      )}
     </> : 
      
      <form onSubmit={onForgotPassword}  className='login-form' >
  <div className='login-div' >
        <div className="h-100 mx-auto forgot-div ">
          <div>
            <h2 style={{ textAlign: 'center', fontSize: '30px', marginBottom: '0', color: '#12104f' }}>Forgot Password</h2>
            <p style={{ textAlign: 'center', color: 'grey', marginTop: '1.5%', marginBottom: '0', fontSize: '12px', color:'#b1b4c6' }}>Enter your credentials to access your account</p>
          </div>
          <div style={{ height: '10%', marginTop: '7%' }}>
            <h6 style={{ color: '#12104f', marginBottom: '3%'}} className='h-font' >Enter Email</h6>
            <input type="text" className="form-control input-box" placeholder="@  john@email.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}  required />
          </div>
          <p style={{ margin: '0', fontSize: '12px', color:'#b1b4c6', marginTop: '1%' }}>Haven't received email?<button type="button" onClick={onForgotPassword} style={{ marginBottom: '5%', textDecoration: 'none', padding: '0', verticalAlign: 'baseline', fontSize: '12px' }} className="btn btn-link">&nbsp;Send again</button> </p>           
          <p style={{color:"green"}}>{resetmessage}</p>
          <button type="submit" className="btn btn-primary w-100 h-font"  style={{ background: '#386af5', marginTop: '7%', height: '7%', borderRadius: '6px'}}>Send password confirmation</button>
          <button type="button" className="btn btn-outline-primary w-100 h-font" style={{height: '7%', borderRadius: '6px', marginTop: '5%'}} onClick={ () => setPasswordreset(false)}>Go Back</button>
        </div>
      </div>
   </form>
    }
      <div style={{ width: '60%', height: '100%', display: 'flex', alignItems: 'center' }} className='non-mobile'>
        <div style={{ height: '85%', width: '95%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h2 style={{ textAlign: 'center', width: '510px', fontSize: '30px', color: '#12104f' }}>Booking - That's Easy for Everyone</h2>
            <p style={{ textAlign: 'center', color: 'grey', marginTop: '1%', width: '460px', fontSize: '12px' }}>By impossible of in difficulty discovered celebrated yet. Justice joy manners boy met resolve produce. Bed head loud next plan rent had easy add him.</p>
          </div>
          <div style={{ height: '70%', width: '85%', marginLeft: 'auto', marginRight: 'auto' }}>
            <img style={{ height: '100%', width: '100%' }} src={screen}></img>
            </div>
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <p style={{ fontSize: 'x-small', color: 'gray' }}>2022 ALL Rights Reserved </p>
            <p style={{ fontSize: 'x-small', color: 'gray' }}>Privacy Policy and Terms of Use</p>
            <p style={{ fontSize: 'x-small', color: 'gray' }}>Developed & Maintained by Cargoa</p>
          </div>
        </div>
      </div>
    </>
     ) :  <form onSubmit={onUpdateData} className='App' style={{ justifyContent: 'center' }} >
      <div className='signup-div1'>	
         <div className='signup-div2'>	
             <div className="signup-div3 signup-div4">
                 <div>
                     <h2 style={{ textAlign: 'center', fontSize: '200%', marginBottom: '0', marginTop: '3%', fontSize: '30px', color: '#12104f' }}>Sign up</h2>
                     <p style={{ textAlign: 'center', color: 'grey', marginTop: '1.5%', marginBottom: '0', fontSize: '12px', color:'#b1b4c6'  }}>Enter your credentials to access your account</p>
                 </div>


                
                 <div className="each-div">	
                     <div className='input-width'>
                         <h6 style={{fontSize: '14px', color: '#12104f'}}>Company</h6>
                         <input type="text" className="form-control b" placeholder="@ LTD company"
                             value={company}
                             required
                             onChange={(e) => setCompany(e.target.value)} />
                     </div>
                     <div className='input-width'>
                         <h6 style={{fontSize: '14px', color: '#12104f'}}>Company Address (Optional)</h6>
                         <input type="text" className="form-control b" placeholder="@ 2400, Copenhagen, Denmark"
                             value={company_address}
                             onChange={(e) => setCompanyAddress(e.target.value)} />
                     </div>
                 </div>

                 {/* <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} className="a">
                     <div style={{ width: '45%' }}>
                         <h6 style={{fontSize: '14px', color: '#12104f'}}>Company Email</h6>
                         <input type="text" className="form-control b" placeholder="@ ltdcompany@email.com"
                             value={company_email}
                             required
                             onChange={(e) => setCompanyEmail(e.target.value)} />
                     </div>
                     <div style={{ width: '45%' }}>
                         <h6 style={{fontSize: '14px', color: '#12104f'}}>Company PAN (Optional)</h6>
                         <input type="text" className="form-control b" placeholder="@ Company PAN"
                             value={company_pan}
                             maxLength='10'
                             minlength='10'
                             onChange={(e) => setCompanyPAN(e.target.value)} />
                     </div>
                 </div> */}
                    <div className="each-div">	
                     <div className='input-width'>
                         <h6 style={{fontSize: '14px', color: '#12104f'}}>Company GST (Optional)</h6>
                         <input type="text" className="form-control b" placeholder="@ Company GST"
                             value={gst}
                             maxLength='10'
                             minLength='10'
                             onChange={(e) => setGst(e.target.value)} />
                     </div>
                     <div className='input-width'>
                         <h6 style={{fontSize: '14px', color: '#12104f'}}>IEC (Optional)</h6>
                         <input type="text" className="form-control b" placeholder="@ IEC"
                             value={cheque}
                             onChange={(e) => setCheque(e.target.value)} />
                     </div>
                 </div>

                 <div className='button-section'>	
                     <button type="submit" className="btn submit-button mx-auto">Create an account</button>
                     <p className="text-center" style={{ marginBottom: '0', height: '25px', marginTop: '0', fontSize: '12px', color:'#b1b4c6' }}>Already have an account? <button type="button" onClick={() => setGetDetails(false)} style={{ marginBottom: '5%', textDecoration: 'none', padding: '0', verticalAlign: 'baseline', fontSize: '12px' }} className="btn btn-link">Sign in</button></p>
                 </div>

             </div>
         </div>
     </div>
 </form>}

    </div>
   
  );
}
export default LoginForm;
import React, { useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import LoginForm from './Login/Loginform';
import HomePage from './Homepage';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import SignupForm from './Signupform';
import ContainerTrackingPage from './Container/ContainerTrackingPage';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckSquare, faCoffee } from "@fortawesome/free-solid-svg-icons";
import ResetPasswordForm from './Login/ResetPasswordform';
import Dashboard from './Dashboard';
library.add( faCheckSquare, faCoffee);
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  const [showLogoutMessage, setShowLogoutMessage] = useState(false);
  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setShowLogoutMessage(true)
    setIsLoggedIn(false);
   
  };

  return (
    <BrowserRouter>
      <Routes>
      <Route path="/tracking/:id" element={
         isLoggedIn ? <ContainerTrackingPage onLogout={handleLogout} /> : <Navigate to='/' > <LoginForm onLogin={handleLogin} logoutMessage={showLogoutMessage}  /></Navigate>
        } />
      <Route path="/dashboard" element={
        isLoggedIn ? <Dashboard onLogout={handleLogout} />:  <Navigate to='/' > <LoginForm onLogin={handleLogin} logoutMessage={showLogoutMessage}  /></Navigate>
        } />
        <Route path="/tracking" element={
         isLoggedIn ? <ContainerTrackingPage onLogout={handleLogout} /> : <Navigate to='/' > <LoginForm onLogin={handleLogin} logoutMessage={showLogoutMessage}  /></Navigate>
        } />
         <Route path="/reset-password/:id" element={
          <ResetPasswordForm />
        } />
        <Route path="/signup" element={
          <SignupForm to="/signup" />
        } />
        <Route path="/login" element={
          isLoggedIn ? <Navigate to="/" /> : <LoginForm onLogin={handleLogin} />
        } />
        <Route path="/" element={
         isLoggedIn?   <Navigate to='/dashboard' /> : <LoginForm onLogin={handleLogin} logoutMessage={showLogoutMessage}  />
        } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
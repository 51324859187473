import React, { useState,useEffect } from 'react';
import axios from 'axios';
import logo from './cargoa.svg'
import profile from './profile.jpg'
import jwt_decode from "jwt-decode";
import { useNavigate} from 'react-router-dom';
import WindowIcon from '@mui/icons-material/Window';
import ArticleIcon from '@mui/icons-material/Article';
import PersonIcon from '@mui/icons-material/Person';
import moment from 'moment';
import Modal from 'react-modal';
import Header from './Navbar/Header';
import Sidebar from './Sidebar/Sidebar';
Modal.setAppElement('#root');
const customStyles = {
    content: {
      top: '77%',
      left: '85%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
function Dashboard({onLogout}) {
    const navigate = useNavigate();

   
  
    
    
    const [errorMessage, setErrorMessage] = useState('');
    const [content, setContent] = useState('');
    const [res,setRes] = useState([]);
    const [data,setData] = useState('')
    const [container,setContainer] =useState('')
    const [height,setHeight] =useState('100px')
    const [showFullList, setShowFullList] = useState(false);
    const [dropdown,setDropdown] =useState('none')
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [feedback,setFeedback] = useState('')
    const [currentIndex, setCurrentIndex] = useState(0);
    function openModal() {
      setModalIsOpen(true);
    }
  
    function closeModal() {
      setModalIsOpen(false);
    }
    let name = jwt_decode(localStorage.getItem('token')).name
    const fetchData = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get('https://app.cargoa.in/loginapi/protected', { headers: { Authorization: `Bearer ${token}` } });
          setContent(response.data);
        } catch (error) {
          console.log(error)
        }
      };
      fetchData();
    useEffect(() => {
        
          try {
           let email = jwt_decode(localStorage.getItem('token')).email
          
           if(email){
         axios.post('https://app.cargoa.in/trackingapi/previoustracking',{email:email}).then((res) =>{ 
           
            setRes(res.data)
                 })
                }
          } catch (error) {
            setErrorMessage(error.response.data);
          }
          
        
 
      
      }, []);
     
      const handleArrowClick = (direction) => {
        const nextIndex = direction === 'right' ? currentIndex + 1 : currentIndex - 1;
        setCurrentIndex(nextIndex);
      };

      const handleLogout = () => {
        localStorage.removeItem('token');
       
        onLogout();
      };
      
        const handleDropDown = () => {
        if(dropdown == 'none'){
            setDropdown('block')
        } else {
            setDropdown('none')
        }
    }
    const handleFeedback = event => {
        event.preventDefault();
        let email = jwt_decode(localStorage.getItem('token')).email
        axios.post('https://app.cargoa.in/loginapi/feedback',{feedback:feedback,email:email}).then((res) => {
            closeModal()
            setFeedback('')
        }).catch(err => console.log(err))
           
                
      };


    return (
        <div className='full-div'>

        <Sidebar index={0} />

        <div className='w-100' vcg>
            <p className='welcome mobile'>Welcome back, <span style={{ color: '#386af5'}}> {name}</span></p>
            <div className='mobile date-div' >
                <p style={{ fontSize: '15px', margin: '0', fontWeight: '600', color: '#c5c5c5'}}>{moment().format("dddd, MMMM DD, YYYY")}</p>
                <button style={{ width: 'fit-content', background: '#386af5', fontSize: '12px', padding: '15px 39px', borderRadius: '5px' }} className='btn btn-primary start-shipment-btn'>Start new shipment</button>

            </div>
           <Header onLogout={onLogout} index={0} />

            <p className='welcome non-mobile'>Welcome back, <span style={{ color: '#386af5'}}> {name}</span></p>
            <div className='justify-content-between non-mobile' style={{ display: 'flex', margin: '1% 2% 2%'}}>
              <p style={{ fontSize: '15px', margin: '0', fontWeight: '600', color: '#c5c5c5'}}>{moment().format("dddd, MMMM DD, YYYY")}</p>
              <button style={{ width: 'fit-content', background: '#386af5', fontSize: '12px', padding: '15px 39px', borderRadius: '5px' }} className='btn btn-primary'>Start new shipment</button>
            </div>



         
          <div className='track-div' >
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
                    <div>
                    <p style={{ margin: '5px 0 0', fontSize: '17px', fontWeight: '600'}}>Recent Activity</p>
                        {/* <p style={{ fontSize: '15px', margin: 0, color: '#c5c5c5', textAlign: 'center'}}>From: <span style={{ color: '#363535', fontWeight: '500'}}>Semarang</span>  To: <span style={{ color: '#363535', fontWeight: '500'}}>Talungagung</span></p> */}
                    </div>
                    <div style={{ color: '386af5', fontSize: '15px', color: '#386af5', cursor: 'pointer'}}>View more</div>
                </div>
                {res.map((d)=>
                <form  onSubmit={()=>navigate(`/tracking/${d.containerId}`)} style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', margin: '2% 0'}}>
                    <div style={{display: 'flex', alignItems: 'center', minWidth: '225px'}}>
                        <div style={{ background: '#f6f6f6', height: '80px', width: '80px', borderRadius: '5px'}}></div>
                        <div style={{ marginLeft: '10%'}} >
                        <p style={{ margin: 0, fontSize: '15px',color: '#c5c5c5',}}>Last Event</p>
                            <p style={{ fontWeight: 600, fontSize: '15px', margin: 0, textAlign: 'center', width: 'fit-content'}}>{moment(d.details[0].response[0].response.eximContainerTrail?.last_event[0].timestamptimezone).format(" MMMM DD, YYYY")}</p>
                        </div>
                    </div>
                    <div>
                        <p style={{ margin: 0, fontSize: '15px',color: '#c5c5c5',}}>ID</p>
                        <p style={{ fontWeight: 600, fontSize: '15px', margin: 0, textAlign: 'center'}}>{d.containerId}</p>
                    </div>
                    <div>
                        <p style={{ margin: 0, fontSize: '15px',color: '#c5c5c5',}}>Date</p>
                        <p style={{ fontWeight: 600, fontSize: '15px', margin: 0, textAlign: 'center'}}>05/03/2023</p>
                    </div>
                    <button type='submit' style={{ width: 'fit-content', background: '#386af5', fontSize: '12px', padding: '15px 57px', borderRadius: '5px' }}   className='btn btn-primary'>Track</button>
                </form>
                )}
                      <div className='mobile' style={{textAlign:"center"}}>
                        <button className='arrow-button' onClick={() => handleArrowClick('left')}><i className="bi bi-chevron-left" style={{ marginRight: '5%'}}></i></button>
                        <button className='arrow-button' onClick={() => handleArrowClick('right')} style={{ marginLeft: '20px'}}><i className="bi bi-chevron-right" style={{ marginRight: '5%'}}></i></button>
                      </div>
          <div>
                

            </div>

          </div>
        {/* } */}
            

        </div>



        <button class="feed-back" onClick={openModal}>Feedback</button>
        <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        // style={{
        //   content: {
        //     width: '400px',
        //     margin: 'auto',
        //     borderRadius: '4px',
        //     padding: '20px'
        //   },
        //   overlay: {
        //     backgroundColor: 'rgba(0, 0, 0, 0.5)'
        //   }
        // }}
      >
        <b>Feedback</b>
        <form onSubmit={handleFeedback}>
       
          <textarea
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          className='textarea' id="feedback" name="feedback" rows="5" cols="50" placeholder='Enter Feedback' required></textarea><br/>
          <button type='submit'  style={{ width: 'fit-content',color:"white", background: '#386af5', fontSize: '12px', padding: '6px 25px', borderRadius: '5px' }} >Submit</button>
        </form>
      </Modal>
    </div>
);
}


export default Dashboard;
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function HomePage({ onLogout }) {
  const [errorMessage, setErrorMessage] = useState('');
  const [content, setContent] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://app.cargoa.in/loginapi/protected', { headers: { Authorization: `Bearer ${token}` } });
        setContent(response.data);
      } catch (error) {
        setErrorMessage(error.response.data);
      }
    };
    fetchData();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    onLogout();
  };

  return (
    <>
      <h1>Protected Content</h1>
      {errorMessage && <div>{errorMessage}</div>}
      <div>{content}</div>
      <button onClick={handleLogout}>Log out</button>
    </>
  );
}

export default HomePage;
import React, { useState } from 'react'
import './Sidebar.css'
import WindowIcon from '@mui/icons-material/Window';
import ArticleIcon from '@mui/icons-material/Article';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';
import logo from '../cargoa.svg'
import MenuIcon from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';
import profile from '../profile.jpg';
export default function Sidebar({onLogout,index}) {
    const [display, setDisplay] = useState(false);
    const [dropdown,setDropdown] =useState('none')
    const navigate =useNavigate()
    const handleDropDown = () => {
        if(dropdown === 'none'){
            setDropdown('block')
        } else {
            setDropdown('none')
        }
    }
    const handleLogout = () => {
      
        localStorage.removeItem('token')

        onLogout();

      };
  return (
    <div className='border-none'>
    <div className='header-div'>
    <img className='home-logo' onClick={()=> navigate('/dashboard')} src ={logo}></img>
    <button className='btn mobile' onClick={() => setDisplay(!display)}>{display? <ClearIcon/> : <MenuIcon/>}</button>
    </div>
    <hr style={{width:'83%'}} className="mx-auto non-mobile"></hr>
    <div style={{width:'83%', marginTop: '10%'}} className="form-group has-search mx-auto non-mobile">
        <span className="bi bi-search form-control-feedback form-controls-feedback"></span>
        <input type="text" className="form-control form-controls" placeholder="Search"/>
    </div>
    <div style={{marginTop: '10%', display: 'flex'}} className='flex-column align-items-start non-mobile'>
    <div className='btn side-button' style={{background: index===0 ? '#f6f6f6':""}} onClick={()=>navigate('/dashboard') }>
         <WindowIcon sx={{color:index===0 ?'#386af5':''}}/>
        <span style={{ marginLeft: '10%'}}>Dashboard</span>
    </div>
    <div className='btn side-button'>
        <ArticleIcon/>
        <span style={{ marginLeft: '10%'}}>Freight Booking</span>
    </div>
    <div className='btn side-button'  style={{background: index===2 ? '#f6f6f6':""}} onClick={()=>navigate('/tracking') } >
        <img src={index===2? "https://img.icons8.com/material-rounded/22/386af5/class.png":" https://img.icons8.com/material-rounded/22/null/class.png"} />
        <span style={{ marginLeft: '10%'}} >Container Tracking</span>
    </div>
    <div className='btn side-button'>
        <PersonIcon />
        <span style={{ marginLeft: '10%'}}>Profile</span>
    </div>
    </div>
    { display? <div className='menu'> 
        <div className='menu-content'>
            <a className="nav-link" style={{ width: '30vh'}}>
                <div className="dropdown" onClick={handleDropDown} style={{ height: '7vh',cursor:"pointer", width: '100%', display: 'flex', alignItems: 'center', border: '1px solid lightgray', borderRadius: '40px', justifyContent: 'space-between'}}>
                    <img src={profile} style={{ marginLeft: '4%', borderRadius: '50%', height: '80%'}}></img>
                    <p style={{  fontSize: '14px', fontWeight: '600', margin: '0'}}>karthi  </p>
                    <i className="bi bi-chevron-down" style={{ marginRight: '5%'}}></i>
                    <div className="dropdown-content" >
                        <a onClick={handleLogout}>Logout</a>
                    </div>
                </div>
            </a>
            <div style={{width:'100%', marginTop: '10%'}} className="form-group has-search">
                <span className="bi bi-search form-control-feedback form-controls-feedback" style={{height:'70px', fontSize: '20px', paddingLeft: '20px'}}></span>
                <input type="text" className="form-control form-controls" style={{height:'70px', paddingLeft: '60px'}} placeholder="Search"/>
            </div>
            <div style={{marginTop: '10%', display: 'flex', width: '100%'}} className='flex-column align-items-start'>
                <div className='btn side-button' style={{background: index===0 ? '#f6f6f6':""}} onClick={()=>navigate('/dashboard') }>
                    <WindowIcon sx={{color:index===0 ?'#386af5':''}}/>
                    <span style={{ marginLeft: '10%'}}>Dashboard</span>
                </div>
                <div className='btn side-button'>
                    <ArticleIcon/>
                    <span style={{ marginLeft: '10%'}}>Freight Booking</span>
                </div>
                <div className='btn side-button'  style={{background: index===2 ? '#f6f6f6':""}} onClick={()=>navigate('/tracking') } >
                    <img src={index===2? "https://img.icons8.com/material-rounded/22/386af5/class.png":" https://img.icons8.com/material-rounded/22/null/class.png"} />
                    <span style={{ marginLeft: '10%'}} >Container Tracking</span>
                </div>
                <div className='btn side-button'>
                    <PersonIcon />
                    <span style={{ marginLeft: '10%'}}>Profile</span>
                </div>
                </div>
        </div>
        </div> : ''
    }
    
</div>
  )
}
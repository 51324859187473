import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import screen from '../screen.png'
import PhoneInput from 'react-phone-input-2'
import './Login.css'
import 'react-phone-input-2/lib/style.css'
import { useParams } from 'react-router-dom';
function ResetPasswordForm({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signupPassword, setSignupPassword] = useState('');
  const [signupConfirmPassword, setSignupConfirmPassword] = useState('');
    const [success,setSuccess] = useState(false)
  let { id } = useParams();

  const onhandleReset =event=>{
    event.preventDefault();
    if(password !='' && password===signupConfirmPassword){
        axios.post('https://app.cargoa.in/loginapi/reset-password',{token:id,password:password}).then(res => setSuccess(true)).catch((err)=> console.log(err))
    }else{
      alert("Password not match")
    }
  }
  const navigate = useNavigate()
  return (
    <div className='App' >
        {!success?          <form onSubmit={onhandleReset} className='login-form' >

        <div className='login-div'>
              <div className="h-100 mx-auto login-div2" style={{ justifyContent: 'center'}}>

                <div>
                  <h2 style={{ textAlign: 'center', fontSize: '30px', marginBottom: '0', color: '#12104f' }}>Change Password</h2>
                  <p style={{ textAlign: 'center', color: 'grey', marginTop: '1.5%', marginBottom: '0', fontSize: '12px', color:'#b1b4c6' }}>Enter your credentials to access your account</p>
                </div>

                <div style={{ height: '10%', marginTop: '7%' }}>
                  <h6 style={{ color: '#12104f', marginBottom: '3%'}} className='h-font' >New Password</h6>
                  <input type="password" className="form-control input-box" placeholder="8 + characters required"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)} required />
                </div>


                <div style={{ height: '10%', marginTop: '7%' }}>
                  <h6 style={{ color: '#12104f', marginBottom: '3%' }} className='h-font' >Confirm New Password</h6>
                  <input type="password" className="form-control input-box" placeholder="8 + characters required"
                    value={signupConfirmPassword}
                    onChange={(e) => setSignupConfirmPassword(e.target.value)} required />
                </div>



                <button type="submit" className="btn btn-primary w-100 h-font"   style={{ background: '#386af5', height: '7%', borderRadius: '4px', marginTop: '7%'}}>Change Password</button>

              </div>
            </div>

          </form>
           : <form className='login-form' >
       
       <div className='login-div'>
             <div className="h-100 mx-auto login-div2" style={{ justifyContent: 'center'}}>
               <div>
                 <h2 style={{ textAlign: 'center', fontSize: '30px', marginBottom: '0', color: '#12104f' }}>Successfully</h2>
                 <h2 style={{ textAlign: 'center', fontSize: '30px', marginBottom: '0', color: '#12104f' }}>changed password</h2>
                 <p style={{ textAlign: 'center', color: 'grey', marginTop: '1.5%', marginBottom: '0', fontSize: '12px', color:'#b1b4c6' }}>Enter your credentials to access your account</p>
               </div>
              
               
               <button type="button" className="btn btn-primary w-100 h-font" onClick={navigate('/login')}  style={{ background: '#386af5', height: '7%', borderRadius: '4px', marginTop: '7%'}}>Sign in</button>
               <button type="button" className="btn btn-outline-primary w-100 h-font" style={{height: '7%', borderRadius: '4px', marginTop: '5%'}}>Sign up</button>
             </div>
           </div>
         </form> }




      <div className='non-mobile' style={{ width: '60%', height: '100%', display: 'flex', alignItems: 'center' }}>
        <div style={{ height: '85%', width: '95%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h2 style={{ textAlign: 'center', width: '510px', fontSize: '30px', color: '#12104f' }}>Booking - That's Easy for Everyone</h2>
            <p style={{ textAlign: 'center', color: 'grey', marginTop: '1%', width: '460px', fontSize: '12px' }}>Bay impossible of in difficulty discovered celebrated yet. Justice joy manners boy met resolve produce. Bed head loud next plan rent had easy add him.</p>
          </div>
          <div style={{ height: '70%', width: '85%', marginLeft: 'auto', marginRight: 'auto' }}>
            <img style={{ height: '100%', width: '100%' }} src={screen}></img>
            </div>
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <p style={{ fontSize: 'x-small', color: 'gray' }}>2022 ALL Rights Reserved Pihr</p>
            <p style={{ fontSize: 'x-small', color: 'gray' }}>Privacy Policy and Terms of Use</p>
            <p style={{ fontSize: 'x-small', color: 'gray' }}>Developed & Maintained by vivasoft.com</p>
          </div>
        </div>
      </div>

    </div>

  );
}
export default ResetPasswordForm; 
import React, { useState,useEffect } from 'react';
import axios from 'axios';
import logo from '../sur.png'
import profile from '../profile.jpg'
import Cargoa from '../cargoa.svg'
import GoogleMapReact from 'google-map-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import './Container.css'
import jwt_decode from "jwt-decode";
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../Navbar/Header';
import Dashboard from '../Dashboard';
import Grid from "@mui/material/Grid";
import WindowIcon from '@mui/icons-material/Window';
import ArticleIcon from '@mui/icons-material/Article';
import PersonIcon from '@mui/icons-material/Person';
import Sidebar from '../Sidebar/Sidebar';

const points = [
    [22.505, 88.09],
    [22.9, 88.6],
  ];
  
const AnyReactComponent = ({ text }) => <div>{text}</div>;
function ContainerTrackingPage({onLogout}) {
    let email = jwt_decode(localStorage.getItem('token')).email
   
    
    const [errorMessage, setErrorMessage] = useState('');
    const [content, setContent] = useState('');
    const [res,setRes] = useState([]);
    const [data,setData] = useState('')
    const [container,setContainer] =useState('')
    const [height,setHeight] =useState('100px')
    const [showFullList, setShowFullList] = useState(false);
    const [dropdown,setDropdown] =useState('none')
    const [loader,setLoader] = useState(false)
    let style = {
        fontSize: "20px"
      };
      let name = jwt_decode(localStorage.getItem('token')).name
    let  {id} = useParams();

      useEffect(() => {
        if(id){
        setContainer(id)
        setLoader(true)
        
            
        axios.post('https://app.cargoa.in/trackingapi/container',{containerNumber:id,email:email}).then(res => {
            setRes(res.data.response)
            setLoader(false)
            console.log(res.data.response)
            
        }).catch((error)=>{
            setRes([])
            setLoader(false)
            setData('No Containers Found')
        })
        }
    }, [id]);
    const handleDropDown = () => {
        if(dropdown == 'none'){
            setDropdown('block')
        } else {
            setDropdown('none')
        }
    }



        const fetchData = async () => {
          try {
            const token = localStorage.getItem('token');
            const response = await axios.get('https://app.cargoa.in/loginapi/protected', { headers: { Authorization: `Bearer ${token}` } });
            setContent(response.data);
          } catch (error) {
            setErrorMessage(error.response.data);
          }
        };
        fetchData();
      
   const handleChangeDetails =()=>{
    setContainer('')
    setRes([])
   }

     
      const handleContainerSubmit =event=>{
        event.preventDefault();
       setLoader(true)
        setRes([])
        axios.post('https://app.cargoa.in/trackingapi/container',{containerNumber:container,email:email}).then(res => {
            setRes(res.data.response)
            console.log(res.data.response)
            setLoader(false)
        }).catch((error)=>{
            setRes([])
            setLoader(false)
            setData('No Containers Found')
            setTimeout(()=>{
                setData('')
            },5000)
        })
      }
    let navigate = useNavigate()
      const handleLogout = () => {
        localStorage.removeItem('token');
        onLogout();
      };

    return (
        <div className='full-div'>

       <Sidebar index={2} />

        <div className='w-100' >
           <Header onLogout={onLogout}  index={2} />
            <form onSubmit={handleContainerSubmit} className='d-flex justify-content-between search-form'>
                <div style={{width:'92.5%'}} className="form-group has-search">
                    <span style={{ height: '9.5vh', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '15px', fontSize: '20px'}} className="bi bi-search form-control-feedback form-controls-feedback"></span>
                    <input type="text" value={container} required minLength='11' maxLength='11'  style={{ height: '9vh'}} className="form-control search-controls" onChange={(e)=> setContainer(e.target.value)} placeholder="Enter the Container Number to Track"/>
                </div>
                <button type='submit'className='btn btn-primary scanner-button'><i className="bi bi-qr-code-scan"></i></button>
            </form>
            {res.length > 0 && res[0].response.eximContainerTrail.res_Message==="Success" ? res.map((d)=>
            <div style={{ minHeight: '77vh',margin:"3% 0"}}>
                <div style={{ height: '277px', margin: '0 2% 1%', borderRadius: '10px', border: '1px  solid lightgrey', display: 'flex'}}>
                    <div style={{ width: '45%', display: 'flex', height: '100%', padding: '15px 0px 15px 2%', justifyContent: 'space-evenly', flexDirection: 'column' }}>
                    <div style={{ display: 'flex',justifyContent: 'space-between', alignItems: 'center'}}>
                                <div style={{ width: '85%', display: 'flex'}}>
                                    <div style={{ height: '40px', width: '40px', display:'flex', alignItems: 'center', justifyContent: 'center', background:'#386af5', borderRadius: '5px'}}><img src="https://img.icons8.com/ios-glyphs/15/ffffff/cargo-ship.png"/></div>
                            <div className='d-flex flex-column' style={{ marginLeft: '5%'}}>
                                {/* <div className='d-flex align-items-center justify-content-center ' style={{ height: '35px', width: '35px', background: '#0d6efd'}}>
                                    <i style={{ color: 'white'}} className="bi bi-truck"></i>
                                </div> */}
                                <p style={{  fontWeight: 600, marginBottom: '0'}}>Container Tracking Code - {d.response.eximContainerTrail.cntrDetail?.cntrno}</p>
                                <p style={{  color: '#b1b4c6', marginBottom: '0' }}> {d.response.eximContainerTrail.cntrDetail?.refflg === "No"? "Standard class":"Reffeer Class" } </p>
                                
                            </div>
                            </div>
                            {/* <button className='price-button'><i className="bi bi-currency-dollar"></i>75</button> */}


                        </div>
                        <div className='c1-line2'>
                        <div className='d-flex justify-content-between' >
                                    <p style={{ fontWeight: 500, marginBottom: '0'}}>{d.response.eximContainerTrail.trackLog && typeof d.response.eximContainerTrail.trackLog[d.response.eximContainerTrail.trackLog.length-1].currentlocation === 'string' ? d.response.eximContainerTrail.trackLog[d.response.eximContainerTrail.trackLog.length-1].currentlocation.split('/')[0] : ''}</p>
                                    <p style={{ fontWeight: 500, marginBottom: '0'}}>{d.response.eximContainerTrail.trackLog && typeof d.response.eximContainerTrail.last_event[0].currentlocation === 'string' ? d.response.eximContainerTrail.last_event[0].currentlocation.split('/')[0] : ''}</p></div>
                        <div className='d-flex justify-content-between' >
                                    <p style={{ fontWeight: 500, marginBottom: '0'}}>{moment(d.response.eximContainerTrail.trackLog[d.response.eximContainerTrail.trackLog.length-1].timestamptimezone).format('MMMM Do hh:mmA')} </p>
                                    <p style={{ fontWeight: 500, marginBottom: '0'}}>{moment(d.response.eximContainerTrail.last_event[0].timestamptimezone).format('MMMM Do hh:mmA')}</p>
                        </div>
                        </div>
                        <hr style={{ height: '2px',borderWidth: '0', background: '#b1b4c6', margin: '1% 0%'}}></hr>
                        <div style={{ display: 'flex', justifyContent: 'space-between',alignItems:"center"}}>
                                <div className='d-flex' style={{ width: '85%', alignItems: 'center',height:"100%"}}>
                                    <div className='container-button'>
                                        Carrier Name
                                    </div>
                                    <div className='container-button'>
                                    {d.response.eximContainerTrail.cntrDetail?.containertype.split(" ").slice(2).join(" ")}
                                    </div>
                                    <div className='container-button'>
                                    Size:{d.response.eximContainerTrail.cntrDetail?.cntrsize}
                                    </div>
                                    <div className='container-button' style={{ marginRight: '0'}}>
                                    {moment(d.response.eximContainerTrail.last_event[0].timestamptimezone).format('Do MMMM YYYY')}
                                    </div>
                                </div>
                                <div className='share-button non-mobile' style={{backgroundColor:"rgb(56, 106, 245)"}} onClick={() => window.location = 'mailto:yourmail@domain.com'}>Share</div>

                                <div className='mobile' style={{ display: 'flex', width: '85%', alignItems: 'center', flexWrap: 'wrap'}}>
                                    <div className='container-button'>
                                        Carrier Name
                                    </div>
                                    <div className='container-button'>
                                    {d.response.cntrDetail.containertype.split(" ").slice(2).join(" ")}
                                    </div>
                                    <div className='container-button'>
                                    Size:{d.response.cntrDetail.cntrsize}
                                    </div>
                                    <div className='container-button' style={{ marginRight: '0'}}>
                                    {moment(d.response.last_event[0].timestamptimezone).format('Do MMMM YYYY')}
                                    </div>
                                    <div className='share-button' style={{backgroundColor:"rgb(56, 106, 245)", marginTop: '3%', marginLeft: '3%'}} onClick={() => window.location = 'mailto:yourmail@domain.com'}>Share</div>
                                </div>
                                

                        </div>
                    </div>
                    <div className='map-div'>
                        
                        <MapContainer center={[d.response.eximContainerTrail.last_event[0].latitude,d.response.eximContainerTrail.last_event[0].longitude]} zoom={6} attributionControl = {false} style={{ height: '100%', width:'100%', borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        {d.response.eximContainerTrail.trackLog.map((data)=>
                        data.latitude? 
                        <>
                            <Marker  position={[data.latitude,data.longitude]}>
                            <p>{data.currentlocation}</p>
                            </Marker>
                   
                        <Polyline positions={points} />
                        </>
                        :""
                        )}
                        </MapContainer>
                    
                    </div>
                </div>

                <div className='info-container'>
                    <div className='info-div'>
                        <div style={{ display: 'flex', flexDirection: 'column', height: 'fit-content', justifyContent: 'space-evenly', padding: '0 3%' }}>
                            <div style={{display:'block'}} className='non-mobile'>
                                <p style={{ fontWeight: '500', fontSize: 'small', margin:0,marginTop:"20px"}}><strong>Departure</strong></p>
                                <p style={{ fontWeight: '400', fontSize: 'smaller',marginBottom:"20px", color: 'gray', margin:'0.2% 0'}}>{moment(d.response.eximContainerTrail.last_event[0].timestamptimezone).format('Do MMMM YYYY')}</p>
                            </div>
                          
                            {showFullList ? (
                            d.response.eximContainerTrail.trackLog.map((data,index)=>
                            <div style={{ display: 'flex',minHeight:"100px", margin: '2% 0', color: moment(data.timestamptimezone).isAfter()? "green":"black" }}>
                                <div style={{ width: '30%' }}>
                                <p style={{ fontWeight: '500', fontSize: 'small', margin:0}}><strong>{moment(data.timestamptimezone).format('DD MMM')}</strong></p>
                                <p style={{ fontWeight: '400', fontSize: 'smaller', margin:'0.2% 0'}}>{moment(data.timestamptimezone).format('h:mm A')}</p>
                                { index == 0? <svg class="blinking">
                                        <circle cx="10" cy="10" r="6" fill="red" />
                                        Sorry, your browser does not support inline SVG.  
                                    </svg> : '' }
                                    

                                
                                <div style={{ borderLeft: '2px solid gray', height: '13%', width: '5%', margin: index==0? '-3% 0% -3% 10%' : '10% 0% -3% 10%'}}></div>
                               {data.transportmode ==="VESSEL"?<img style={{margin:"0 5%"}} src="https://img.icons8.com/material-rounded/18/null/cargo-ship.png"/> :   <img style={{margin:"0 5%"}} src="https://img.icons8.com/ios-filled/18/null/truck-weight-max-loading.png"/>} 
                              
                                <div style={{ borderLeft: '2px solid gray', height: '13%', width: '5%', margin: '0% 0% 0% 10%'}}></div>
                                </div>
                                <div style={{ width: '65%' }}>
                                <p style={{ fontWeight: '400', fontSize: 'small', margin:'0'}}>Estimated Departure</p>
                                <p style={{ fontWeight: '600', margin:'0.2% 0',fontSize:"big"}}>{typeof data.currentlocation === 'string' ? data.currentlocation.split('/')[0] : ''}</p>
                                <p style={{ fontWeight: '400', margin:'0.2% 0'}}>{data.currentlocation}</p>
                                <p style={{ fontWeight: '400', fontSize: 'smaller', margin:'0'}}>{data.eventname}</p>
                                </div>
                            </div>
                              ) ): (
                                <>
                                <div style={{ display: 'flex',minHeight:"100px", margin: '2% 0'}}>
                                    <div style={{ width: '30%' }}>
                                    <p style={{ fontWeight: '500', fontSize: 'small', margin:0}}><strong>{moment(d.response.eximContainerTrail.trackLog[0].timestamptimezone).format('DD MMM')}</strong></p>
                                    <p style={{ fontWeight: '400', fontSize: 'smaller', margin:'0.2% 0'}}>{moment(d.response.eximContainerTrail.trackLog[0].timestamptimezone).format('h:mm A')}</p>
                                    <div style={{ borderLeft: '2px solid gray', height: '40%', width: '5%', margin: '15% 0% 5% 10%'}}></div>
                                    </div>
                                    <div style={{ width: '65%' }}>
                                    <p style={{ fontWeight: '400', fontSize: 'small', margin:'0'}}>Estimated Departure</p>
                                    <p style={{ fontWeight: '500', margin:'0.2% 0'}}>{typeof d.response.eximContainerTrail.trackLog[0].currentlocation === 'string' ? d.response.eximContainerTrail.trackLog[0].currentlocation.split('/')[0] : ''}</p>
                                    <p style={{ fontWeight: '400', margin:'0.2% 0'}}>{d.response.eximContainerTrail.trackLog[0].currentlocation}</p>
                                    <p style={{ fontWeight: '400', fontSize: 'smaller', margin:'0'}}>{d.response.eximContainerTrail.trackLog[0].eventname}</p>
                                    </div>
    
                        </div>
                        {d.response.eximContainerTrail.trackLog[1] ? (
                                    <div style={{ display: 'flex',minHeight:"100px", opacity: '50%', margin: '2% 0'}}>
                                        <div style={{ width: '30%' }}>
                                        <p style={{ fontWeight: '500', fontSize: 'small', margin:0}}><strong>{moment(d.response.eximContainerTrail.trackLog[1].timestamptimezone).format('DD MMM')}</strong></p>
                                        <p style={{ fontWeight: '400', fontSize: 'smaller', margin:'0.2% 0'}}>{moment(d.response.eximContainerTrail.trackLog[1].timestamptimezone).format('h:mm A')}</p>
                                        </div>
                                        <div style={{ width: '65%' }}>
                                        <p style={{ fontWeight: '400', fontSize: 'small', margin:'0'}}>Estimated Departure</p>
                                        <p style={{ fontWeight: '500', margin:'0.2% 0'}}>{typeof d.response.eximContainerTrail.trackLog[0].currentlocation === 'string' ? d.response.eximContainerTrail.trackLog[0].currentlocation.split('/')[0] : ''}</p>
                                        <p style={{ fontWeight: '400', margin:'0.2% 0'}}>{d.response.eximContainerTrail.trackLog[1].currentlocation}</p>
                                        <p style={{ fontWeight: '400', fontSize: 'smaller', margin:'0'}}>{d.response.eximContainerTrail.trackLog[1].eventname}</p>
                                    </div>
                                    </div>
                                    ) : '' }
                                </>
                                
                                )}
                        
                            <div style={{ textAlign: 'center'}}>
                              
                            {showFullList=== true?    <button className='btn btn-primary view-more-btn ' onClick={() => setShowFullList(false)} >View less details</button>
                               :  <button className='btn btn-primary view-more-btn ' onClick={() => setShowFullList(true)} >View more details</button>}
                            </div>
                            <div style={{display: 'flex', width: '90%',marginTop:"4%",marginBottom:"4%", marginLeft: 'auto', marginRight: 'auto', justifyContent: 'space-between'}}>
                                <div>
                                    <p style={{ fontWeight: '600', margin:0}}>Basic Package - 20ft</p>
                                    <p style={{ fontWeight: '400', fontSize: 'smaller', margin:'0.2% 0'}}>Fits up to 2B 300 kg & 33 m3</p>
                                </div>
                                <div>
                                    <p style={{ fontWeight: '500', fontSize: 'small', margin:0}}>1</p>
                                </div>
                            </div>
                            {/* <hr style={{ color: 'grey', height: '2px',borderWidth: '0', background: 'grey', margin: '1% 0%'}}></hr>
                            <div style={{display: 'flex',marginTop:"4%",marginBottom:"4%", width: '90%', marginLeft: 'auto', marginRight: 'auto', justifyContent: 'space-between'}}>
                                <div>
                                    <p style={{ fontWeight: '500', fontSize: 'small', margin:0}}><strong>Total Price</strong></p>
                                </div>
                                <div>
                                    <p style={{ fontWeight: '500', fontSize: 'small', margin:0}}><strong>USD 916.00</strong></p>
                                </div>
                            </div> */}
                        </div>
                       
                    </div>
                    
                </div>
                <div className='tracking-btn-div'>
                        <button type="button" className="btn btn-custom tracking-bottom-btn tracking-bottom-btn1">Contact us</button>
                        <button style={{ background:"#386af5"}} type="button" className="btn btn-primary tracking-bottom-btn tracking-bottom-btn2" onClick={handleChangeDetails}>Change details</button>
                        </div>
            </div>
            ):     <div className='container-div'>
            <div className=' mx-auto boat-icon'>
                <img src="https://img.icons8.com/ios-glyphs/20/386af5/cargo-ship.png"/>
            </div>
            <div>
                
                <p className='p-div'>Enter details to see container tracking details</p>
                {/* <p style={{ fontSize: '15px', margin: 0, color: '#c5c5c5', textAlign: 'center'}}>Enter your crendentials to access your account</p> */}
                {data? <p style={{color:"red",marginLeft:"28%"}}>{data}</p>:""}
               
            </div>
            {loader === true?
            <>
                        <div class="spinner-border spinner-div" ></div>
            <div className=''>        <p  style={{ fontWeight: 600, margin: 0, fontSize: '19px',marginLeft:"30%"}}>Loading...</p>
        <p  style={{ fontSize: '15px', margin: 0, color: '#c5c5c5', textAlign: 'center'}}>It can take upto 60 seconds</p></div>
        </> :""}
      </div>}

       

        </div>

       

    </div>
);
}


export default ContainerTrackingPage;
import React,{useState} from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import profile from '../profile.jpg'
import { Link } from 'react-router-dom';
import './Header.css'
export default function Header({onLogout,index}) {
    const [dropdown,setDropdown] =useState('none')
    const [active, setActive] = useState(null);

  const handleClick = (index) => {
  
    setActive(index);
  };

    const navigate = useNavigate();
    const handleLogout = () => {
      
        localStorage.removeItem('token')

        onLogout();
    //  navigate('/')
      };
      
        const handleDropDown = () => {
        if(dropdown === 'none'){
            setDropdown('block')
        } else {
            setDropdown('none')
        }
    }
  return (
    <nav className="navbar justify-content-between align-items-center nav-bar">
    <div style={{ display: 'flex', width: '40%', justifyContent: 'flex-start', alignItems: 'center'}} className='non-mobile'>                    
      <button className='arrow-button' onClick={()=> navigate('/dashboard')}><i className="bi bi-chevron-left" style={{ marginRight: '5%'}}></i></button>
      <button className='arrow-button' onClick={()=> navigate('/tracking')} style={{ marginLeft: '20px'}}><i className="bi bi-chevron-right" style={{ marginRight: '5%'}}></i></button>
      <div style={{ marginLeft: '5%'}}>
        {index === 0? <>
            <p style={{ fontWeight: 600, margin: 0, fontSize: '26px'}}>Dashboard - Cargo</p>
            <p style={{ fontSize: '12px', margin: 0, fontWeight: '500'}}> <span style={{ color: '#c5c5c5'}} >DASHBOARD</span> </p>
                      </>  :""}
        {index === 2? <>
          <p style={{ fontWeight: 600, margin: 0, fontSize: '26px'}}>Container Tracking</p>
                  <p style={{ fontSize: '12px', margin: 0, fontWeight: '500'}}> <span style={{ color: '#c5c5c5'}} >DASHBOARD</span> / CONTAINER TRACKING</p>
        </> :""}

      </div>
    </div>
    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'}} className='mobile'>                    
      <div>
        {index === 0? <>
            <p style={{ fontWeight: 600, margin: 0, fontSize: '24px'}}>Dashboard - Cargo</p>
            <p style={{ fontSize: '10px', margin: 0, fontWeight: '500'}}> <span style={{ color: '#c5c5c5'}} >DASHBOARD</span> </p>
                      </>  :""}
        {index === 2? <>
          <p style={{ fontWeight: 600, margin: 0, fontSize: '24px'}}>Container Tracking</p>
                  <p style={{ fontSize: '10px', margin: 0, fontWeight: '500'}}> <span style={{ color: '#c5c5c5'}} >DASHBOARD</span> / CONTAINER TRACKING</p>
        </> :""}

      </div>
      <div>
        <button className='arrow-button' onClick={()=> navigate('/dashboard')}><i className="bi bi-chevron-left" style={{ marginRight: '5%'}}></i></button>
        <button className='arrow-button' onClick={()=> navigate('/tracking')} style={{ marginLeft: '20px'}}><i className="bi bi-chevron-right" style={{ marginRight: '5%'}}></i></button>
      </div>
    </div>


            <div className='non-mobile' style={{ listStyle: 'none', display: 'flex', width: '60%', justifyContent: 'flex-end', margin: 0, alignItems: 'center'}}>

                {/* <div style={{display: 'flex', marginRight: '5%'}}> */}
                <Link to='/dashboard' className='nav-style' style={{ color: index === 0 ? '#386af5' : 'black',textDecoration:"none" }} onClick={() =>{ handleClick(0)}} > Dashboard </Link>
                            <Link className='nav-style' style={{ color: index === 1 ? '#386af5' : 'black',textDecoration:"none" }} onClick={() => handleClick(1)}> Freight Booking </Link>
                            <Link to='/tracking' className='nav-style' style={{ color: index === 2 ? '#386af5' : 'black',textDecoration:"none" }} onClick={() =>{ handleClick(2)}} > Container Tracking </Link>
                            <Link className='nav-style nav-profile' style={{ color: index === 3 ? '#386af5' : 'black' ,textDecoration:"none"}} onClick={() => handleClick(3)} > Profile </Link>  
                {/* </div> */}
                    <a className="nav-link" style={{ width: '23vh'}}>
                    <div className="dropdown" onClick={handleDropDown} style={{ height: '7vh',cursor:"pointer", width: '100%', display: 'flex', alignItems: 'center', border: '1px solid lightgray', borderRadius: '40px', justifyContent: 'space-between'}}>
                        <img src={profile} style={{ marginLeft: '4%', borderRadius: '50%', height: '80%'}}></img>
                        <p style={{  fontSize: '14px', fontWeight: '600', margin: '0'}}>karthi  </p>
                        <i className="bi bi-chevron-down" style={{ marginRight: '5%'}}></i>
                        <div className="dropdown-content" >
                            <a onClick={handleLogout}>Logout</a>
                        </div>
                    </div>
                    </a>
                {/* </li> */}
            </div>

            

                    

            </nav>
  )
}